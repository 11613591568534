@import url("https://fonts.googleapis.com/css2?family=Fraunces:ital,wght@1,600&display=swap");
@import url("https://use.typekit.net/hfk8kkx.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.home {
  display: flex; /* Added this line */
  flex-direction: column; /* Added this line */
  justify-content: center; /* Added this line */
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  max-width: 100vw;
  color: #000000;
  font-family: Helvetica;

}

.first {
  padding-top: 5vh;
}

.menu-items a {
  font-size: 1rem;
}

.descrip {
  display: flex;
  font-size: 0.65em;
  font-weight: bold;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  padding: 1em;
}

.descrip-item {
  text-align: center; /* Center-align the text within each item */
  flex: 1; /* Allow the items to grow and take up equal space */
}

.logo-home {
  width: 15vw;
  z-index: 1001;
  animation: rotateLogo 8s linear infinite; /* Adjust the duration and other properties as needed */
}

@keyframes rotateLogo {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title {
  display: flex;
  font-size: 8.9em;
  justify-content: center;
  white-space: nowrap;
  align-items: center;
  height: 35vh;
  overflow: hidden;
}

.pages-menu {
  display: flex;
  align-items: center;
  height: 3vh;
  font-size: 2em;
  justify-content: flex-end;
  list-style: none;
}

a {
  color: #000000;
  text-decoration: none;
}

.dou {
  display: inline-block;
  white-space: nowrap;
  position: relative;
  left: 100vw;
}

.lum-gfx {
  display: inline-block;
  white-space: nowrap;
  position: relative;
  left: 200vw;
}

.flyer-gfx {
  display: inline-block;
  white-space: nowrap;
  position: relative;
  left: 300vw;
}

.gfx-img {
  width: 17vw;
}

.playlists {
  color: #017950;
}

.work-home{
  color: #017950;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.work-home.fade-in {
  opacity: 1;
}

/* sites css */
.sites-home {
  min-height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  color: #000000;
  font-family: Helvetica;
  font-style: italic;
  /* cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><circle cx="8" cy="8" r="7" fill="rgb(0, 0, 0)" stroke="rgb(255, 255, 234)" stroke-width="2"/></svg>')
      8 8,
    auto; */
}

.sites-logo {
  display: flex;
  justify-content: flex-start;
  max-width: 100vw;
  z-index: 1000;
}

.logo-img {
  width: 6vw;
  position: fixed;
  z-index: 1000;
}

.port-sites {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: nowrap;
}

.port-sites img {
  width: 27vw;
}

.lum-sites {
  display: flex;
  justify-content: center;
}

.ecom-sites {
  display: flex;
  justify-content: center;
}

.ecom-sites img {
  width: 60vw;
}

.vrdy-gif {
  width: 35vw;
}

.words li {
  padding-left: 20px;
  margin-bottom: 1.3rem;
  font-size: 0.8rem;
}

.gfx-sites {
  display: flex;
  justify-content: center;
}

.gfx-sites img {
  width: 30vw;
}

.dou-img {
  margin-top: 3vh;
  width: 8vw;
}

.port-container {
  position: relative;
}

.words {
  opacity: 0;
  transition: opacity 0.3s ease;
  margin: 0 auto;
  padding: 5vw;
  list-style: none;
  text-align: center;
  margin-bottom: 5vh;
}

.port-sites:hover + .words {
  opacity: 1;
}

.words li {
  font-family: "franklin-gothic-atf", serif;
  font-weight: 400;
  font-style: normal;
}

.footer {
  background-color: transparent;
  color: #00A36C;
  padding: 3vh 0;
  position: fixed;
  bottom: 0;
  width: 100vw;
  font-family: "franklin-gothic-atf", serif;
  font-weight: 400;
  font-style: normal;
  display: flex;
  justify-content: flex-end;
}

.footer-p {
  font-size: 0.6em;
  margin-right: 3vw;
  font-family: Helvetica;
  font-style: italic;
  color: #017950;
}


.content-wrapper {
  display: flex;
  flex-direction: column;
}

.contact {
  width: 100vw;
  height: 100vh;
}

.email{
  font-weight: bolder;
}

.description-con {
  width: 100vw;
  height: 60vh;
  margin: 10vh auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Helvetica;
  font-style: italic;
  font-weight: 400;
}

.description-con p {
  width: 50vw;
}

.socials ul {
  width: 100vw;
  display: flex;
  font-family: Helvetica;
  font-weight: 600;
  justify-content: space-around;

  flex-direction: row;
  text-decoration: none;
  list-style-type: none;
  color: #017950;
}

.socials-link {
  color: #017950;
}

@media only screen and (max-width: 767px) {
  body {
    max-width: 100vw;
  }

  .home {
    height: 100vh;
    overflow-y: hidden;
    justify-content: center;
  }

  .contact{
    height: 100vh;
    justify-content: center;
    overflow-y: hidden;
  }

  .mobile-none{
    display: none;
  }

  .title {
    font-size: 2.6em;
  }

  .descrip {
    font-size: 0.3em;
  }

  .pages-menu {
    display: none;
  }

  .logo-home {
    width: 40vw;
  }

  .words {
    opacity: 1;
    margin: 0 auto;
    padding-bottom: 6.5rem;
    padding-top: 3rem;
  }

  .words li {
    font-size: 10px;
    line-height: 1.6;
    width: 75vw;
    margin: 0 auto;
    padding-bottom: 1.5em;
  }

  .lum-sites img {
    width: 50vw;
  }

  .description-con {
    height: 80vh;
  }

  .description-con p {
    width: 80vw;
    font-size: 12px;
  }

  .socials ul {
    font-size: 12px;
  }

  .logo-img {
    width: 15vw;
    position: fixed;
  }

  .styling {
    width: 40vw;
    margin: 0 10px;
  }
}
