.content {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
}

.menuWrapper {
  max-width: 20vw;
  height: auto;
  position: fixed;
  margin-top: 20%;
  margin-left: 1%;
}

.menuItems {
  font-family: Helvetica;
  font-weight: normal;
  font-size: 0.8em;
  margin: 1vh auto;
  padding: 0px 8%;
  color: #414141;
  cursor: pointer;
}

.menuItems.active {
  color: #017950;
  font-weight: bolder;
}

.site-img{
  width: 40vw;
  object-fit: contain;
  max-height: 80vh;
}

.home-gif{
margin: 10vh 23vw;
}

.contents{
  width: 72vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.site-descrip{
  opacity: 1;
  transition: opacity 0.3s ease;
  margin: 0 auto;
  padding: 5vw;
  list-style: none;
  text-align: center;
  font-size: small;
  margin-bottom: 5vh;
}

.cd-descrip{
  opacity: 1;
  transition: opacity 0.3s ease;
  list-style: none;
  text-align: center;
  font-size: medium;
  padding-top: 10vh;
  padding-bottom: 8vh;
}

.contents:hover .site-descrip{
  opacity: 1;
}


.textContent {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-left: 100px;
  margin-bottom: 1em;
}

.text {
  display: flex;
  flex-direction: column;
  font-family: Helvetica;
  font-size: 0.8em;
}

.titleText {
  font-size: 1.2em;
  height: auto;
  font-weight: bolder;
  font-family: Helvetica;
}

.titleYear {
  font-size: 1.5em;
  height: auto;
  font-weight: bolder;
  font-family: Helvetica;
  margin: 20px 0;
}

.ping-pong-game {
  max-width: 300px;
  margin: auto;
  padding: 20px;
  text-align: center;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ping-pong-game button {
  margin: 10px 0;
}


.modal {
  position: fixed;
  top: 0;
  right: -100%; /* Start off the screen */
  width: 400px; /* Adjust as needed */
  height: 100%;
  background: white;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease-in;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  z-index: 1000; /* Ensure it's above other content */
}

.modal.open {
  right: 0; /* Slide in */
}

.modal-content img {
  max-width: 100%; /* Ensure image fits in the modal */
  margin: 20vh auto 5vh auto;
}

.modal-content {
  text-align: center;
  position: relative;
}

/* Close button styles */
.close-modal {
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust to move to the right side */
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 24px; /* Large "X" */
  line-height: 1;
  padding: 0;
  z-index: 2; /* Ensure it's above other content */
}

/* Optional: Add more styling to make the close button look nicer */
.close-modal:hover {
  color: #555; /* Change color on hover */
}
@media (max-width: 800px) {
  .cont{
    max-width: 100vw;
    overflow-x: hidden;
  }

  .menuWrapper {
    margin-top: 35vh;
  }

  .home-gif{
    margin-top: 30vh;
    margin-left: 5vw;
    width: 60vw;
  }

  .site-img{
    width: 50vw;
    object-fit: contain;
    max-height: 80vh;
  }

  .contents{
    width: 48vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .site-descrip{
    opacity: 1;
    font-size: 0.5em;

  }

  .menuItems {
    font-size: 0.7em;
  }
  .textContent {
    margin-left: 2%;
  }
  .text {
    font-size: 0.5em;
  }
  .titleText {
    font-size: 0.8em;
  }

  .titleYear {
    font-size: 1em;
  }
}

