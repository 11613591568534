.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
  
  .images-wrapper {
    display: flex;
    padding: 20px;
    margin-left: 35vw; /* Match the width of the first image */
    width: 65vw; /* Remaining viewport width */
    justify-content: space-around; /* Space the images evenly */
  }
  
  .first-image {
    width: 35vw;
    position: fixed;
    left: 0;
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Center vertically */
    flex-shrink: 0;
  }
  
  .other-images {
    display: flex;
    flex-direction: column;
    width: 100%; /* Full width of the container */
    justify-content: space-between; /* Space the images evenly */
  }
  
  .other-image {
    width: 20vw;
    height: auto;
    padding-bottom: 20px;
  }
  
  /* Mobile responsiveness starts here */
  @media (max-width: 768px) {
    .first-image {
      width: 100%;
      position: static;
      padding-right: 0;
      top: auto;
      transform: none;
    }
  
    .other-images {
      width: 100%;
    }
  
    .images-wrapper {
      flex-direction: column;
      margin-left: 0;
      width: 100%;
    }
  
    .other-image {
      width: 100%;
    }
  }
  